label {
  font-size: $label-font-size;
  font-weight: $label-font-weight;
  font-style: italic;
}

// same height as buttons
.form-control {
  height: $input-btn-height;
}

// remove shadow on inputs focus
.form-control:focus,
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.custom-control-label {
  // give some more clickable sensitivity
  padding: 0 10px 3px 0;
  display: inline-block;
  cursor: pointer;

  // this fixes the vertical alignment problem due to the smaller font size
  &:before,
  &:after {
    top: 0;
  }
}
