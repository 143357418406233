@import "~slick-carousel/slick/slick.scss";
@import "~@kuus/slick-themes/base";
// background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 100 100'%3E%3Cpath d='M 5,50 97.5,5 97.5,95 Z'/%3E%3C/svg%3E");
// background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 100 100'%3E%3Cpath d='M 95,50 5,95 5,5 z'/%3E%3C/svg%3E");

.slick {
  &-next {
    @include arrow(right, map-get($Colors, black), 20px, 16.7px);
  }

  &-prev {
    @include arrow(left, map-get($Colors, black), 20px, 16.7px);
  }
}
