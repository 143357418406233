@import "~@acanto/october-js-framework/formbuilder/uploader";

/**
 * Form builder
 *
 * @see http://..../plugins/renatio/formbuilder/assets/js/form.js
 */
$multiuploader-spinner-size: 1.4em;
$multiuploader-spinner-color: $input-border-color;

//
.has-error {
  // @extend .has-danger;
}

.form-field-error-label {
  @extend .invalid-feedback;
}

// file upload field
div.control-multi-file-uploader {
  @extend .col-12;
  @extend .col-lg-4;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .clickable {
    z-index: 1;
    left: $grid-gutter-width / 2 !important;
    right: $grid-gutter-width / 2 !important;
    bottom: $input-border-width !important;
    border: 0 !important;
    border-radius: 0 !important;
    border-bottom: $input-border-width solid $input-border-color !important;
  }

  .content {
    position: relative; // the absolute label (p.placeholder) uses this
    display: flex;
    height: $input-btn-height;
    margin-top: 1.5em; // make space for the label on top
    align-items: flex-end;
    padding: 0 !important;
    background: $input-bg;

    p.placeholder {
      @extend label;
      position: absolute;
      top: -2em;
      left: 0;
      margin: 0;
      padding: 0 0 0.5em 0;
      color: $label-color !important;
    }
  }

  .dz-preview {
    display: flex;
    align-items: center;
    height: $input-btn-height;
    padding: 0 4px 0 0;
    margin: 0 !important;
    padding-left: $input-btn-padding-x !important;
    background: transparent !important;

    .thumbnail {
      display: none;
    }

    .dz-details {
      margin-left: 0 !important;
      min-height: 0 !important;

      .dz-filename {
        font-weight: normal !important;
      }
      .dz-size {
        display: none !important;
      }

      .activity {
        right: -2.3em !important;
        width: 2em !important;
        height: 2em !important;
        margin: 0 !important;
      }
    }

    .action-panel {
      right: -1em !important;

      .delete {
        border: 0 !important;
      }
    }

    .dz-progress {
      bottom: ($input-border-width * 2) !important;
      height: $input-border-width !important;

      .dz-upload {
        background-color: $input-border-color !important;
      }
    }

    .loading:before,
    .loading:after {
      border-width: 2px !important;
      width: $multiuploader-spinner-size !important;
      height: $multiuploader-spinner-size !important;
      margin: -2px 0 0 0;
    }

    .loading:after {
      border-color: $multiuploader-spinner-color transparent transparent;
    }
  }
}
