// this should affects only the outer `.container-fluid` containers
.container-fluid {
  @include media-breakpoint-up(xxl) {
    padding-left: $grid-gutter-width-outer-xxl;
    padding-right: $grid-gutter-width-outer-xxl;

    > .row:not(.no-gutters) {
      margin-right: -$grid-gutter-width-outer-xxl;
      margin-left: -$grid-gutter-width-outer-xxl;

      > [class^="col-"] {
        padding-right: $grid-gutter-width-outer-xxl;
        padding-left: $grid-gutter-width-outer-xxl;
      }
    }
  }
}

.col-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // @include media-breakpoint-up(lg) {}
}
