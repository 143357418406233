@import "~bootstrap/scss/_mixins";
@import "~sass-rem/rem"; // @see https://github.com/pierreburel/sass-rem

/**
 * Media queries mixins
 */
@mixin desktop() {
  @include media-breakpoint-up(lg) {
    @content;
  }
}

@mixin tablet-desktop() {
  @include media-breakpoint-up(md) {
    @content;
  }
}

@mixin tablet() {
  @include media-breakpoint-between(md, xl) {
    @content;
  }
}

@mixin mobile() {
  @include media-breakpoint-down(sm) {
    @content;
  }
}

/**
 * Container/grid mixins
 */
@mixin fullwidthContainer() {
  margin-left: -($grid-gutter-width / 2);
  margin-right: -($grid-gutter-width / 2);
}

@mixin fullwidthMobileContainer() {
  @include mobile() {
    @include fullwidthContainer();
  }
}

@mixin forceContainerMaxWidth($width, $selector: ".container") {
  $custom-breakpoint: $width + ($grid-gutter-width * 2);
  $override: " !important";

  @media (min-width: $custom-breakpoint) {
    #{$selector} {
      max-width: #{$width$override};
    }
  }
}

@mixin centeredMaxWidth($width) {
  max-width: $width;
  margin-left: auto;
  margin-right: auto;
}

/**
 * Arrow css
 */
@mixin arrow($direction, $color, $height, $width) {
  @if $direction == right {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: ($height / 2) 0 ($height / 2) $width;
    @include arrowColor($direction, $color);
  }

  @if $direction == left {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: ($height / 2) $width ($height / 2) 0;
    @include arrowColor($direction, $color);
  }
}

@mixin arrowColor($direction, $color) {
  @if $direction == right {
    border-color: transparent transparent transparent $color;
  }

  @if $direction == left {
    border-color: transparent $color transparent transparent;
  }
}

/**
 * Spacing proportinal mixins
 */
$Devices: (mobile, tablet, desktop);

@mixin spacing(
  $size: sm,
  $factor: 1,
  $property: "padding",
  $devices: $Devices,
  $dictionary: $Spacing
) {
  @include spacingTop($size, $factor, $property);
  @include spacingBottom($size, $factor, $property);
  @include spacingLeft($size, $factor, $property);
  @include spacingRight($size, $factor, $property);
}

@mixin spacingVertical(
  $size: sm,
  $factor: 1,
  $property: "padding",
  $devices: $Devices,
  $dictionary: $Spacing
) {
  @include spacingTop($size, $factor, $property, $devices, $dictionary);
  @include spacingBottom($size, $factor, $property, $devices, $dictionary);
}

@mixin spacingHorizontal(
  $size: sm,
  $factor: 1,
  $property: "padding",
  $devices: $Devices,
  $dictionary: $Spacing
) {
  @include spacingLeft($size, $factor, $property, $devices, $dictionary);
  @include spacingRight($size, $factor, $property, $devices, $dictionary);
}

@mixin spacingTop(
  $size: sm,
  $factor: 1,
  $property: "padding",
  $devices: $Devices,
  $dictionary: $Spacing
) {
  @include _spacing($size, $factor, $property, "top", $devices, $dictionary);
}

@mixin spacingBottom(
  $size: sm,
  $factor: 1,
  $property: "padding",
  $devices: $Devices,
  $dictionary: $Spacing
) {
  @include _spacing($size, $factor, $property, "bottom", $devices, $dictionary);
}

@mixin spacingLeft(
  $size: sm,
  $factor: 1,
  $property: "padding",
  $devices: $Devices,
  $dictionary: $Spacing
) {
  @include _spacing($size, $factor, $property, "left", $devices, $dictionary);
}

@mixin spacingRight(
  $size: sm,
  $factor: 1,
  $property: "padding",
  $devices: $Devices,
  $dictionary: $Spacing
) {
  @include _spacing($size, $factor, $property, "right", $devices, $dictionary);
}

@mixin _spacing(
  $size: sm,
  $factor: 1,
  $property: "padding",
  $direction: "top",
  $devices: $Devices,
  $dictionary: $Spacing
) {
  // allow devices to be just "mobile" as a string not as a list
  @if $devices == mobile {
    @include mobile() {
      #{$property}-#{$direction}: map-find($dictionary, $devices, $size) *
        $factor;
    }
    // otherwise loop through devices and create media queried styles
  } @else {
    @each $device in $devices {
      @if $device == mobile {
        #{$property}-#{$direction}: map-find($dictionary, $device, $size) *
          $factor;
      }
      @if $device == tablet {
        @include tablet() {
          #{$property}-#{$direction}: map-find($dictionary, $device, $size) *
            $factor;
        }
      }
      @if $device == desktop {
        @include desktop() {
          #{$property}-#{$direction}: map-find($dictionary, $device, $size) *
            $factor;
        }
      }
    }
  }
}

/**
 * Typography mixins
 */
@mixin typography(
  $component: "p",
  $variant: "regular",
  $dictionary: $Typography
) {
  @if ($variant == "uppercase") {
    text-transform: uppercase;
  }
  @include typographyFontSize($component, $variant, $dictionary);
  @include typographyLineHeight($component, $variant, $dictionary);
}

@mixin typographyFontSize(
  $component: "p",
  $variant: "regular",
  $dictionary: $Typography
) {
  $font-size-px: map-find($dictionary, $component, $variant, "fontSize");
  // reuse the responsive-font-size mixin that comes with Bootstrap 4.3
  @include font-size(map-find($dictionary, $component, $variant, "fontSize"));
}

@mixin typographyLineHeight(
  $component: "p",
  $variant: "regular",
  $dictionary: $Typography
) {
  $font-size-px: strip-unit(
    map-find($dictionary, $component, $variant, "fontSize")
  );
  $line-height-px: strip-unit(
    map-find($dictionary, $component, $variant, "lineHeight")
  );
  $line-height: $line-height-px / $font-size-px;

  line-height: #{$line-height}em;
}

/**
 * Shortcut that groups together the atomic typography placeholders/mixins
 */
@mixin title($component, $variant: "regular", $fontWeight: bold) {
  @extend %font-family-headings;
  @include typography($component, $variant);
  font-weight: $fontWeight;
  word-break: break-word;
  margin: 0;
}

@mixin text($keys, $fontWeight: false, $fontStyle: false) {
  @include typography($keys);
  margin: 0;
  @if $fontWeight {
    font-weight: $fontWeight;
  }
  @if $fontStyle {
    font-style: $fontStyle;

    // reduce letter spacing on italic texts (title likes, e.g. News titles)
    @if $fontStyle == italic {
      letter-spacing: 0.05em;
    }
  }
}

/**
 * Gradient backgorund shortcut
 */
@mixin gradientBg($gradientName: "") {
  @extend %gradient-bg;
  background-image: url("../#{$PATH_IMG}/gradient-#{$gradientName}.jpg");
}

/**
 * Card proportioned
 * it keeps all cards with same proportion but allowing them to grow beyon that,
 * hence the float trick.
 */
@mixin cardProportioned($percentage) {
  float: left;
  width: 100%;

  &:before {
    content: "";
    padding-bottom: $percentage;
    display: block;
    float: left;
    width: 1px;
  }
}

// avoid FOUC before slick initialisation
// @see https://github.com/kenwheeler/slick/issues/1741#issuecomment-154062718
@mixin slickFixFouc() {
  &__slide {
    display: none;
  }
  &__slide:first-child,
  &.slick-initialized &__slide {
    display: block;
  }
}

@mixin responsiveTable(
  $breakpoint-down: md,
  $breakpoint-up: lg,
  $rowSpacer: $spacer,
  $hoverColor: rgba(255, 255, 255, 0.15)
) {
  // Shared style
  td {
    font-weight: 300;
    font-style: italic;
  }

  th,
  [data-row-title] {
    font-weight: 400;
    font-style: normal;
  }

  [data-cell-title] {
    font-style: normal;
  }

  // text is wrapped in <p> tags with inline styles..reset it
  p:first-child:last-child {
    display: inline;
    text-align: inherit;
    font-size: inherit;
  }

  // Mobile style
  @include media-breakpoint-down($breakpoint-down) {
    &,
    thead,
    tbody,
    tfoot,
    tr,
    td {
      display: block;
    }

    // careful here sometimes the headings might be in a thead or just be the
    // first row of the tbody (in that case use selector `tr:first-child`
    thead {
      display: none;
    }

    td {
      position: relative;
      white-space: normal;
      width: 100% !important; // override CMS inline style
      text-align: left !important; // override CMS inline style
    }

    td[data-row-title] {
      padding: $spacer 0 ($spacer / 2) 0;
      font-size: 120%;
    }

    td[data-cell-title] {
      vertical-align: middle;
    }

    tbody td[data-cell-title] {
      font-style: italic;
      &:before {
        content: attr(data-cell-title);
        display: inline-block;
        margin-right: 20px;
        font-weight: bold;
      }
    }

    [data-cell-title]:hover {
      background: rgba(255, 255, 255, 0.15);
    }

    // manage some RichTextEditor glitches
    br:first-child,
    br:last-child {
      display: none;
    }
    br {
      display: inline;
      &:after {
        content: " | ";
        opacity: 0.3;
      }
    }

    // on mobile the first row is hidden, so it is the second that does
    // not get the top border
    [data-head] {
      display: none;
    }

    // hide unneeded borders on mobile table version
    [data-row-title] {
      border-top: 0;
      border-bottom: 0;
    }
    [data-row-title] + [data-cell-title] {
      border-top: 0;
    }
    [data-cell-title]:last-of-type {
      border-bottom: 0;
    }
  }

  // Desktop style
  @include media-breakpoint-up($breakpoint-up) {
    // td:not(:first-child) {
    //   white-space: nowrap;
    // }

    tbody tr:first-child {
      th,
      td {
        padding-top: $rowSpacer;
      }
    }

    tbody tr:last-child {
      th,
      td {
        padding-bottom: $rowSpacer;
      }
    }

    th,
    td:first-child {
      padding: ($spacer / 3) $rowSpacer;
    }

    td {
      padding: ($spacer / 3) $rowSpacer;
      font-style: normal;
    }

    tr[data-body]:hover td {
      background: rgba(255, 255, 255, 0.15);
    }

    // [data-body] td,
    [data-body] [data-cell-title] {
      vertical-align: middle;
    }

    [data-head] [data-row-title],
    [data-head] [data-cell-title],
    [data-body]:last-child td {
      border-bottom: 0;
    }
  }
}

@mixin sectionProporion($proportion: 50) {
  @media (min-width: 1920px) {
    min-height: calc(50vw - #{$navbar-height});
  }
}

@mixin singleColumnSlider($margin-horizontal: 40px) {
  flex-wrap: nowrap;
  overflow: hidden;

  &.slick-slider {
    margin: 0 $margin-horizontal;

    .col-12 {
      padding: 0 !important;
    }
  }
}

@mixin formColorBlue() {
  // tweak form colors according
  .form-control,
  .form-control:focus {
    background: map-get($Colors, powder-blue);
    border-color: map-get($Colors, powder-blue);
    color: map-get($Colors, charcoal-grey-two);
  }
  .form-control:focus {
    border-color: map-get($Colors, cloudy-blue);
  }
}

@mixin contactFormInsideHalfColumn() {
  display: flex;
  align-items: center;
  justify-content: center;
  background: map-get($Colors, bright-yellow);

  .Form {
    @include media-breakpoint-up(md) {
      max-width: 80%;
      margin: 0 auto;
    }
  }

  // force vertical columns stack
  [class^="col-"] {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }

  .form-control-submit {
    margin-top: $spacer;
  }
}
