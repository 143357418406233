%btnAsLink {
  &.btn {
    padding-left: 0;
    padding-right: 0;
  }

  &:focus {
    box-shadow: none;
  }
}

.btn {
  @extend %font-family-headings;
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
  padding: 13.5px 36px; // all buttons should be 45px height
  min-height: $input-btn-height;
  text-transform: $btn-text-transform;
  transition: background-position 0.33s ease-in-out,
    background-color 0.33s ease-in-out;
  background-color: map-get($Colors, charcoal-grey-two);
  background-size: 200% auto;
  color: white;
  border: 0;
  // TODO: check if it is needed after microcopy has been inserted, this avoids
  // that buttons overflow the screen edge, expanding the outer containers too
  word-break: break-word;

  &:not(:disabled):hover {
    background-position: right center;
  }

  /**
   * Button underline (link like)
   */
  &-underline {
    @extend %btnAsLink;
    @extend %linkDecoration;
    @extend %linkDecorationActive;
    background: transparent;
    color: map-get($Colors, charcoal-grey-two);
    &:after {
      transition: background 0.5s ease-in-out;
    }
    &:hover {
      background: transparent;
    }

    /**
     * Button underline blue
     */
    &--blue {
      &:after {
        background: linear-gradient(
          to right,
          map-get($Colors, light-blue),
          map-get($Colors, charcoal-grey-two)
        );
      }
      &:hover::after {
        background: linear-gradient(
          to right,
          map-get($Colors, charcoal-grey-two),
          map-get($Colors, light-blue)
        );
      }
    }

    /**
     * Button underline yellow
     */
    &--yellow {
      &:after {
        background: linear-gradient(
          to right,
          map-get($Colors, bright-yellow),
          map-get($Colors, yellowish-tan)
        );
      }
      &:hover::after {
        background: linear-gradient(
          to right,
          map-get($Colors, yellowish-tan),
          map-get($Colors, bright-yellow)
        );
      }
    }
  }

  /**
   * Button light (link like)
   */
  &-light {
    @extend %btnAsLink;
    @extend %linkDecorationHover;
    color: map-get($Colors, charcoal-grey-two);
    background: #ffffff;
    &:hover {
      background: #ffffff;
    }
  }

  /**
   * Button white
   */
  &-white {
    color: map-get($Colors, charcoal-grey-two);
    background: #ffffff;
    &:hover {
      background: #ffffff;
    }
  }

  /**
   * Button yellow
   */
  &-yellow {
    color: map-get($Colors, charcoal-grey-two);
    background-image: linear-gradient(
      to right,
      map-get($Colors, bright-yellow) 0%,
      #ffff00 51%,
      map-get($Colors, bright-yellow) 100%
    );
  }

  /**
   * Button medium
   */
  &-medium {
    color: map-get($Colors, charcoal-grey-two);
    background-image: linear-gradient(
      to right,
      map-get($Colors, powder-blue) 0%,
      map-get($Colors, light-blue) 51%,
      map-get($Colors, powder-blue) 100%
    );
  }

  /**
   * Button dark
   */
  &-dark {
    background-image: linear-gradient(
      to right,
      map-get($Colors, light-blue) 0%,
      map-get($Colors, charcoal-grey-two) 51%,
      map-get($Colors, light-blue) 100%
    );
    color: white;
    &:hover {
      color: white;
    }
  }

  /**
   * Button darkest
   */
  &-darkest {
    background-image: linear-gradient(
      to right,
      map-get($Colors, light-blue) 0%,
      map-get($Colors, charcoal-grey) 51%,
      map-get($Colors, light-blue) 100%
    );
    color: white;
    &:hover {
      color: white;
    }
  }
}
