.MenuDesktopBar {
  @include make-container();

  &--languages {
    font-size: $navbar-font-size-small;
  }

  &__list {
    @extend %listUnstyled;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;
    min-height: calc(100vh - #{$navbar-height});
    @include centeredMaxWidth(map-get($container-max-widths, xxl));
    padding: $spacer;

    @include media-breakpoint-only(md) {
      column-gap: $navbar-nav-link-padding-x / 2;
    }
    @include media-breakpoint-only(lg) {
      column-gap: $navbar-nav-link-padding-x / 1.5;
    }
    @include media-breakpoint-up(xl) {
      column-gap: $navbar-nav-link-padding-x;
    }

    &Item {
      flex: 0 0 100%;
      display: flex;
      justify-content: center;
      padding: 0;
      margin-left: 0 !important;
    }

    &Link {
      color: $body-color;
      &:focus {
        outline: none;
      }
    }
  }
}
