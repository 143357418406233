/**
 * Constants
 */
$PATH_FONTS: "../fonts/";
$PATH_IMG: "../images";

/**
* App global variables
*/
$App-transition: 0.18s ease-in-out;
$Colors: (
  white: #ffffff,
  black: #000000,
  ocean-blue: #006eb7,
  azure: #009ee2,
  bright-yellow: #ffec00,
  yellowish-tan: #fff57f,
  charcoal-grey-two: #41434d,
  charcoal-grey: #2c2c36,
  cloudy-blue: #b5c1cc,
  powder-blue: #d4e7f3,
  light-blue: #58cffb
);
$Typography: (
  small: (
    regular: (
      fontSize: 11px,
      lineHeight: 17px
    ),
    uppercase: (
      fontSize: 11px,
      lineHeight: 15px
    )
  ),
  p: (
    regular: (
      fontSize: 15px,
      lineHeight: 23px
    ),
    uppercase: (
      fontSize: 15px,
      lineHeight: 21px
    )
  ),
  h6: (
    regular: (
      fontSize: 18px,
      lineHeight: 26px
    ),
    uppercase: (
      fontSize: 18px,
      lineHeight: 23px
    )
  ),
  h5: (
    regular: (
      fontSize: 26px,
      lineHeight: 33px
    ),
    uppercase: (
      fontSize: 26px,
      lineHeight: 28px
    )
  ),
  h4: (
    regular: (
      fontSize: 30px,
      lineHeight: 36px
    ),
    uppercase: (
      fontSize: 30px,
      lineHeight: 33px
    )
  ),
  h3: (
    regular: (
      fontSize: 36px,
      lineHeight: 42px
    ),
    uppercase: (
      fontSize: 36px,
      lineHeight: 40px
    )
  ),
  h2: (
    regular: (
      fontSize: 49px,
      lineHeight: 56px
    ),
    uppercase: (
      fontSize: 49px,
      lineHeight: 53px
    )
  ),
  h1: (
    regular: (
      fontSize: 72px,
      lineHeight: 84px
    ),
    uppercase: (
      fontSize: 72px,
      lineHeight: 79px
    )
  )
);

$Spacing: (
  mobile: (
    sm: 10px,
    md: 40px,
    lg: 80px
  ),
  tablet: (
    sm: 15px,
    md: 45px,
    lg: 90px
  ),
  desktop: (
    sm: 20px,
    md: 50px,
    lg: 100px
  )
);

/**
* Bootstrap helpers variables that will be used below
* (here the variables naming follows bootstrap style) and are used below
*/
$line-height-base: 1.53;
$font-family-serif: "";
$input-btn-height: 45px;
$input-border-width-focus: 1px;
$label-font-size: 11px;
$label-font-weight: 300;
$navbar-height: 70px;
$navbar-height-mobile: $navbar-height;
$navbar-font-size: 18px;
$navbar-font-size-md: 16px;
$navbar-font-size-small: 0.83em;
$navbar-nav-link-padding-y: (
    $navbar-height - ($navbar-font-size * $line-height-base)
  ) / 2;
$grid-gutter-width-outer-xxl: 45px;

/**
* Bootstrap custom variables
*/
$enable-rounded: false;
$enable-responsive-font-sizes: true;
$gray-100: #f4f4f4;
$gray-700: #474747;
$gray-900: #1f1f1f; // same as $body-color
$primary: #000;
$body-color: map-get($Colors, charcoal-grey-two);
$small-font-size: map-find($Typography, "small", "regular", "fontSize");
$font-size-base: 1rem;
$h1-font-size: map-find($Typography, "h1", "regular", "fontSize");
$h2-font-size: map-find($Typography, "h2", "regular", "fontSize");
$h3-font-size: map-find($Typography, "h3", "regular", "fontSize");
$h4-font-size: map-find($Typography, "h4", "regular", "fontSize");
$h5-font-size: map-find($Typography, "h5", "regular", "fontSize");
$h6-font-size: map-find($Typography, "h6", "regular", "fontSize");
$headings-line-height: 1.09em;
$headings-font-weight: normal;
$font-family-sans-serif: "d-din", -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-headings: "d-din_condensed", $font-family-sans-serif;
$font-family-body: $font-family-sans-serif;
$navbar-padding-y: 0;
$navbar-nav-link-padding-x: 2.77em;
$navbar-brand-padding-y: 0;
$navbar-light-color: map-get($Colors, charcoal-grey-two);
$navbar-light-hover-color: map-get($Colors, black);
$btn-padding-x: 20px;
$card-bg: $gray-100;
$label-color: map-get($Colors, charcoal-grey-two);
$label-color-focus: map-get($Colors, charcoal-grey);
$input-bg: map-get($Colors, yellowish-tan);
$input-border-width: 1px;
$input-border-color: $input-bg;
$input-focus-border-color: map-get($Colors, ocean-blue);
$input-btn-font-size: 18px;
$input-btn-padding-y: 0.45rem;
$input-btn-padding-x: 1.1rem;
$input-btn-padding-y-sm: 0.3rem;
$input-btn-padding-x-sm: 0.9rem;
$input-btn-padding-y-lg: 0.4rem;
$input-btn-padding-x-lg: 1.5rem;
$custom-checkbox-indicator-border-radius: 0;
$custom-control-indicator-border-color: map-get($Colors, charcoal-grey-two);
$custom-control-indicator-focus-border-color: map-get($Colors, ocean-blue);
$custom-control-indicator-border-width: 1px;
$custom-control-indicator-size: 1.2rem;
$custom-control-indicator-bg: transparent;
$custom-control-indicator-checked-bg: map-get($Colors, charcoal-grey-two);
$custom-control-indicator-checked-color: white;
$custom-control-indicator-checked-border-color: $custom-control-indicator-border-color;
// $grid-gutter-width: 30px;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1340px,
  // 1200px
    xxl: 1600px
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1280px,
  // 1140px
    xxl: 1785px,
  // ...
);

@import "~bootstrap/scss/_variables";

/**
 * Bootstrap helpers variables that use variables defined above
 * (here the variables naming follows bootstrap style)
 */
$navbar-nav-link-padding-x-mobile: $grid-gutter-width / 2;
$btn-default-color: white;
$btn-default-color-hover: white;
$btn-default-background-hover: map-get($Colors, azure);
$btn-default-background-hover: map-get($Colors, ocean-blue);
$btn-default-border-color-hover: map-get($Colors, ocean-blue);
$btn-default-color-hover: white;
$btn-text-transform: uppercase;

/**
 * Components and utils $variables
 */
$App-lazy-bg: transparent;
$AppHeader-bg: #fff;
$AppHeader-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.18);
$MenuDesktop-bg: map-get($Colors, powder-blue);
$MenuDesktop-box-shadow: $AppHeader-box-shadow;
$MenuMobile-bg: $MenuDesktop-bg;
$Jumbotron-text-max-width: 575px;
$linkDecoration-thickness: 1px;
$linkDecoration-color: map-get($Colors, ocean-blue);
$linkDecoration-color-hover: map-get($Colors, black);
$img-bg--broken: "data:image/svg+xml,%3Csvg version='1.0' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m6.6875 5.125c-1.108 0-2 0.892-2 2v11.094l26.094 0.9375 13.125 12.406 11.531-6.9062v-17.531c0-1.108-0.892-2-2-2h-46.75zm22.094 23.562l-24.094 6.0938v18.719c0 1.108 0.892 2 2 2h46.75c1.108 0 2-0.892 2-2v-16.188l-14.062 5.2188-12.594-13.844z' fill='%23b3b3b3' fill-rule='evenodd' stroke='%23333' stroke-linecap='round' stroke-linejoin='round' stroke-width='3.125'/%3E%3C/svg%3E";
$img-bg--broken-size: 32px;
$checkbox-checked-color: white;
$checkbox-checked-bg-image: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='#{$checkbox-checked-color}' d='m21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z'/%3e%3c/svg%3e"),
  "#",
  "%23"
);
