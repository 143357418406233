@import "MenuDesktopMega";
@import "MenuDesktopBar";

.MenuDesktop {
  @include media-breakpoint-between(md, lg) {
    font-size: $navbar-font-size-md;
  }

  @include media-breakpoint-down(md) {
    display: none !important;
  }

  &--open .MenuDesktop__mega {
    opacity: 1;
  }
  &--slideready .MenuDesktop__mega {
    // display: none;
    transition: transform $App-transition;
    transform: translateY(-100%);
  }
  &--slideready.MenuDesktop--open .MenuDesktop__mega {
    // display: block;
    transform: translateY(0);
    transform-origin: top;
  }

  &__mega {
    z-index: $zindex-dropdown + 1;
    position: absolute;
    top: $navbar-height;
    left: 0;
    right: 0;
    background: $MenuDesktop-bg;
    transition: opacity $App-transition;
    opacity: 0;
    overflow: hidden;
    box-shadow: $MenuDesktop-box-shadow;
  }

  .nav-link {
    // tweak the %linkDecoration style
    &:after {
      bottom: 14px;
    }
  }

  .nav-link.open,
  .nav-link.active {
    @extend %linkDecorationActive;
    cursor: default;
  }
  .nav-link--hamburger {
    // hide decoration on hamburger!
    &:after {
      display: none;
    }
  }

  .nav-item {
    display: flex;
    min-height: $navbar-height;

    @include media-breakpoint-only(md) {
      margin-left: $navbar-nav-link-padding-x / 2;
    }
    @include media-breakpoint-only(lg) {
      margin-left: $navbar-nav-link-padding-x / 1.5;
    }
    @include media-breakpoint-up(xl) {
      margin-left: $navbar-nav-link-padding-x;
    }
  }

  .nav-link {
    display: flex;
    align-items: center;
    padding: 0 !important;
    @extend %linkDecorationHover;
    cursor: pointer;
    min-height: $navbar-height;
  }
}
