/**
 * Utility class used on the <div> wrapping RTE content | raw from the CMS
 */
.RTE {
  // hide empty paragraphs
  p br:first-child:last-child {
    display: none;
  }

  // remove margin for one paragraph only RTE fields, in the case, for instance,
  // of a one line text in a Plugin Page, e.g. `Reserved area` page
  p:first-child:last-child {
    margin: 0;
  }
}

/**
 * Conventional class used by content editor through the RTE fields in the CMS
 */
.p {
  @include text(p);
}
