/**
 * To manage Pages Plugins content coherently, we have variants to set with
 * a numeric modifier class like `.Pages--v1`
 */
.Pages {
  &__wrap {
    // @extend .col-12; // in the template, to clarify that we are using a `.col`
    @extend .col-md-10;
    @extend .offset-md-1;
    @extend .col-lg-6;
    @extend .offset-lg-3;
    @include spacingVertical(lg);

    > .Pages__title {
      text-align: center;
    }
    .Pages--v2 & {
      text-align: center;
    }
  }

  &__title {
    @include title(h1);

    & + .Pages__subtitle,
    & + .Pages__content {
      @include spacingTop(md);
    }
  }

  &__subtitle {
    .Pages--v1 & {
      @include title(h3);
      @include spacingBottom(md);
    }

    .Pages--v2 & {
      @include text(h6, bold);
      @include spacingBottom(sm);
    }
  }

  &__content {
    @include text(p);
    @extend .RTE;
  }
}
