/**
 * @fileOverview
 * 
 * In this folder we just import scss files that are shared accross different
 * stylesheets and that do not emit any css. These files are just used as tools.
 * They divide in [functions](https://sass-lang.com/documentation/at-rules/function),
 * [mixins](https://sass-lang.com/documentation/at-rules/mixin),
 * [placeholders](https://sass-lang.com/documentation/style-rules/placeholder-selectors)
 * and [variables](https://sass-lang.com/documentation/variables).
 * 
 * The content of these files is always made available to `.scss` files imported
 * in JavaScript files bundled with WebPack through the use of
 * [sass-resources-loader](https://github.com/shakacode/sass-resources-loader).
 * 
 * ``js
 *  loader: require.resolve("sass-resources-loader"),
 *  options: {
 *    resources: [
 *      path.join(paths.src.styles, "/config/_.scss"),
 *    ]
 *  }
 *```
 */
@import "../config/functions";
@import "../config/variables";
@import "../config/mixins";
@import "../config/placeholders";

@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/close";

.Form {
  width: 100%;
  @include spacingVertical(md);
  background: map-get($Colors, bright-yellow);

  &__title {
    @include text(h5, normal, italic);
  }

  &__text {
    @include spacingBottom(md, 0.9);
  }

  .form-control-submit {
    display: flex;
    align-items: flex-end;
    @include media-breakpoint-down(md) {
      margin-top: $spacer;
    }
  }
}
