/**
 * @fileOverview
 * 
 * In this folder we just import scss files that are shared accross different
 * stylesheets and that do not emit any css. These files are just used as tools.
 * They divide in [functions](https://sass-lang.com/documentation/at-rules/function),
 * [mixins](https://sass-lang.com/documentation/at-rules/mixin),
 * [placeholders](https://sass-lang.com/documentation/style-rules/placeholder-selectors)
 * and [variables](https://sass-lang.com/documentation/variables).
 * 
 * The content of these files is always made available to `.scss` files imported
 * in JavaScript files bundled with WebPack through the use of
 * [sass-resources-loader](https://github.com/shakacode/sass-resources-loader).
 * 
 * ``js
 *  loader: require.resolve("sass-resources-loader"),
 *  options: {
 *    resources: [
 *      path.join(paths.src.styles, "/config/_.scss"),
 *    ]
 *  }
 *```
 */
@import "../config/functions";
@import "../config/variables";
@import "../config/mixins";
@import "../config/placeholders";

.AppHeader,
#Menu_scroller {
  text-transform: uppercase;
  @extend %font-family-headings;
  font-weight: normal;
  font-size: $navbar-font-size;
}

.AppHeader {
  background: $AppHeader-bg;
  transition: box-shadow $App-transition;

  &.is-sticked {
    box-shadow: $AppHeader-box-shadow;
  }

  .navbar {
    padding: 0;

    &-brand {
      display: flex;
      align-items: center;
      min-height: $navbar-height;
      margin: 0;
      img {
        width: 160px;
        height: auto;
        @media (min-width: 375px) {
          width: 200px;
          height: 48px;
        }
      }
    }

    @include media-breakpoint-down(md) {
      &-brand {
        padding: 0 $navbar-nav-link-padding-x-mobile;
      }
    }
  }
}

// sticky header
.AppHeader {
  z-index: $zindex-sticky + 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  + section {
    // 423 is an approximate value that should account for the height of the
    // header plus the height of the footer... to get a sort of sticky footer
    // to the bottom edge, viewable on scroll
    min-height: calc(100vh - 423px);
  }
}

body {
  padding-top: $navbar-height;
}
