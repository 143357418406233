$MenuDesktop__figures-width: 488px;
$MenuDesktop__list-width: 423px;
$MenuDesktop__figuresSlider-width: $MenuDesktop__figures-width;
$MenuDesktop__figuresSlider-height: $MenuDesktop__figuresSlider-width;

.MenuDesktopMega {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: $spacer;
  @include centeredMaxWidth(map-get($container-max-widths, xxl));
  min-height: calc(100vh - #{$navbar-height});

  // &__figures {
  //   width: $MenuDesktop__figures-width;
  //   opacity: 0;
  //   transition: opacity $App-transition;

  //   .is-hover & {
  //     opacity: 1;
  //   }

  //   &Slider {
  //     width: $MenuDesktop__figuresSlider-width;
  //     height: $MenuDesktop__figuresSlider-height;
  //     overflow: hidden;

  //     .mediaElement {
  //       background-size: contain;
  //     }
  //   }
  // }

  &__list {
    @extend %listUnstyled;
    // flex-basis: $MenuDesktop__list-width;
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    flex-direction: column;
    // max-height: 200px;
    // padding-left: $nav-link-padding-x;

    @include media-breakpoint-only(md) {
      column-gap: $navbar-nav-link-padding-x / 2;
    }
    @include media-breakpoint-only(lg) {
      column-gap: $navbar-nav-link-padding-x / 1.5;
    }
    @include media-breakpoint-up(xl) {
      column-gap: $navbar-nav-link-padding-x;
    }

    &2 {
      width: 300px;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      align-content: flex-start;
      position: absolute;
      top: 0;
      visibility: hidden;
      opacity: 0;
      transition: all 400ms ease-in-out;
      left: 0;
      z-index: 1;
      max-height: calc(100vh - #{$navbar-height} - 15px);
      overflow-y: scroll;
    }

    &Title {
      display: flex;
      min-height: $navbar-height;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 0;
      flex: 1 0 300px;
      width: 300px;

      &:hover {
        > span {
          &:after {
            background: $linkDecoration-color-hover;
            left: 0;
            right: 0;
          }
        }
      }
      &.is-visible {
        & + .MenuDesktopMega__list2 {
          visibility: visible;
          opacity: 1;
          left: 100%;
        }
      }

      span {
        pointer-events: none;
        @extend %linkDecoration;
        cursor: default;
        &:after {
          bottom: -7px;
        }
      }
    }

    &Container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: flex-start;
      align-content: flex-start;
      // padding-bottom: $spacer * 2;
      position: relative;
      z-index: 0;
      flex: 0 0 100%;
    }

    &Item {
      flex: 0 0 100%;
      // width: 100%;
      // max-width: $MenuDesktop__list-width;
      text-align: center;
      justify-content: center;
      margin: 0 !important;
      padding: 0;
      position: relative;
      z-index: 0;

      &:nth-child(1) {
        .MenuDesktopMega__list2 {
          top: 0px;
        }
      }

      &:nth-child(2) {
        .MenuDesktopMega__list2 {
          top: -$navbar-height;
        }
      }

      &:nth-child(3) {
        .MenuDesktopMega__list2 {
          top: -$navbar-height * 2;
        }
      }

      &1 {
        // flex: 0 0 100%;
      }

      &2 {
        flex: 1 0 100%;
        /* min-height: unset !important;
        & > .nav-link {
          min-height: $navbar-height / 1.33 !important;
          &:after {
            bottom: 2px !important;
          }
        } */
      }
    }
    &Link {
      // @extend %Menu-product-name;
      color: $body-color;

      &:focus {
        outline: none;
      }
    }
  }
}
