// @font-face {
//   font-family: "MyFont";
//   src: url("#{$PATH_FONTS}MyFont.ttf") format("truetype");
//   font-weight: 100;
//   font-style: normal;
// }

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 17, 2019 */
@font-face {
  font-family: "d-din";
  src: url("#{$PATH_FONTS}d-din-bold.woff2") format("woff2"),
    url("#{$PATH_FONTS}d-din-bold.woff") format("woff"),
    url("#{$PATH_FONTS}d-din-bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "d-din";
  src: url("#{$PATH_FONTS}d-din-italic.woff2") format("woff2"),
    url("#{$PATH_FONTS}d-din-italic.woff") format("woff"),
    url("#{$PATH_FONTS}d-din-italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "d-din";
  src: url("#{$PATH_FONTS}d-din.woff2") format("woff2"),
    url("#{$PATH_FONTS}d-din.woff") format("woff"),
    url("#{$PATH_FONTS}d-din.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "d-din_condensed";
  src: url("#{$PATH_FONTS}d-dincondensed-bold.woff2") format("woff2"),
    url("#{$PATH_FONTS}d-dincondensed-bold.woff") format("woff"),
    url("#{$PATH_FONTS}d-dincondensed-bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "d-din_condensed";
  src: url("#{$PATH_FONTS}d-dincondensed.woff2") format("woff2"),
    url("#{$PATH_FONTS}d-dincondensed.woff") format("woff"),
    url("#{$PATH_FONTS}d-dincondensed.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
