.AppFooter {
  color: map-get($Colors, cloudy-blue);

  a {
    color: map-get($Colors, cloudy-blue);
  }

  & &Colophon {
    @include spacingVertical(md);
    background: map-get($Colors, charcoal-grey);

    &__newsletter {
      @include media-breakpoint-down(sm) {
        padding-bottom: 40px;
      }
    }

    &__social {
      @include media-breakpoint-down(md) {
        padding-bottom: 40px;
      }

      &Title {
        @include text(h5, normal, italic);
        padding-bottom: 20px;
        text-align: center;
        @include media-breakpoint-up(lg) {
          text-align: left;
        }
      }

      &Icons {
        display: flex;
        font-size: rem(20px);
        justify-content: center;
        @include media-breakpoint-up(lg) {
          justify-content: flex-start;
        }

        a {
          @include spacingRight(sm, 2.25);
          &:hover {
            .icon {
              fill: white;
            }
          }
        }

        .icon {
          fill: currentColor;
        }
      }
    }

    &__certs {
      text-align: center;
      @include media-breakpoint-up(lg) {
        text-align: right;
      }
      &Img {
        display: inline-block;
        width: 120px;
        margin: auto 5px;
        padding-bottom: 20px;
      }
    }

    &__links {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include media-breakpoint-up(lg) {
        align-items: flex-end;
      }

      a {
        padding: 10px 0;
        display: inline-block;
        font-size: 11px;
        @extend %linkDecorationHover;
        @include title(small, uppercase);
        &:after,
        &:hover:after {
          background: map-get($Colors, cloudy-blue);
        }
        @include media-breakpoint-up(md) {
          text-align: center;
        }
        @include media-breakpoint-up(lg) {
          text-align: right;
        }
        &.highlight {
          font-size: 15px;
          color: map-get($Colors, bright-yellow);
          text-align: right;
        }
        &.active {
          color: map-get($Colors, bright-yellow);
        }
      }
    }
  }

  &Bar {
    padding-top: ($grid-gutter-width / 2);
    padding-bottom: ($grid-gutter-width / 2);
    background: map-get($Colors, black);
    font-size: rem(12px);

    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include media-breakpoint-down(md) {
        display: block;
        text-align: center;
      }
    }

    &__address {
      @include media-breakpoint-down(md) {
        padding-bottom: ($grid-gutter-width / 2);
      }
      // put address in one/two lines on big screen
      // @include media-breakpoint-up(xl) {
      //   br:not(:first-of-type) {
      //     content: " | ";
      //     padding: 0 8px;
      //   }
      // }
    }

    &__credits {
      @include desktop() {
        float: right;
        margin-left: $spacer;
      }
    }
  }
}
