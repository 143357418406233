.lazy {
  &-error {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url($img-bg--broken);
      background-size: $img-bg--broken-size;
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0.5;
    }
  }
}
