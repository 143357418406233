// @see tpl https://gist.github.com/kuus/39dfe38c72452a716e278c7dbc143800

$CookieBot-wrap-gutter: $grid-gutter-width / 2 !default;
$CookieBot-spacer: $spacer !default;
$CookieBot-font-family: $font-family-base !default;
$CookieBot-headings-font-weight: bold !default;

$CookieBot-checkbox-size: 13px !default;
$CookieBot-checkbox-border-width: $custom-control-indicator-border-width !default;
$CookieBot-checkbox-border-color: $custom-control-indicator-border-color !default;
$CookieBot-checkbox-checked-bg: $custom-control-indicator-checked-bg !default;
$CookieBot-checkbox-checked-bg-image: $checkbox-checked-bg-image !default;

$CookieBot-label-color: $label-color !default;
$CookieBot-label-font-weight: 400 !default; // $label-font-weight !default;
$CookieBot-font-size-base: 12px !default;

$CookieBot-btn-min-width: 120px !default;
$CookieBot-btn-default-color: $body-color !default;
$CookieBot-btn-border-color: $input-border-color !default;
$CookieBot-btn-border-width-y: 0 !default;
$CookieBot-btn-border-width-x: 0 !default;
$CookieBot-btn-font-size: $font-size-base !default;
$CookieBot-btn-padding-y: $input-btn-padding-y !default;
$CookieBot-btn-padding-x: $input-btn-padding-x !default;
$CookieBot-btn-text-transform: $btn-text-transform !default;
$CookieBot-btn-font-weight: bold !default;
$CookieBot-btn-color-hover: $body-color !default;
$CookieBot-btn-background-hover: transparent !default;
$CookieBot-btn-border-color-hover: $btn-default-border-color-hover !default;

#CybotCookiebotDialog,
#CybotCookiebotDialog a,
#CybotCookiebotDialog div,
#CybotCookiebotDialogBodyContentTitle {
  font-family: $CookieBot-font-family !important;
}

#CybotCookiebotDialogBodyContentTitle {
  font-weight: $CookieBot-headings-font-weight !important;
}

#CybotCookiebotDialogBody,
#CybotCookiebotDialogDetailBody {
  padding-left: $CookieBot-wrap-gutter !important;
  padding-right: $CookieBot-wrap-gutter !important;
}

#CybotCookiebotDialogPoweredbyLink {
  margin-left: 0 !important;
}

#CybotCookiebotDialogBodyLevelButtons {
  margin-left: 0 !important;
}

#CybotCookiebotDialogBodyLevelButtonsSelectPane,
.CybotCookiebotDialogDetailBodyContentTab {
  border-radius: 0 !important;
}

#CybotCookiebotDialogBodyLevelDetailsWrapper {
  vertical-align: middle !important;
  padding: 0 !important;
  text-align: center !important;
}

a#CybotCookiebotDialogBodyLevelButtonAccept {
  margin: 0 !important;
  width: auto !important;
  white-space: normal !important;
  min-width: $CookieBot-btn-min-width !important;
  color: $CookieBot-btn-default-color !important;
  background-color: transparent !important;
  border-color: $CookieBot-btn-border-color !important;
  border-top-width: $CookieBot-btn-border-width-y !important;
  border-right-width: $CookieBot-btn-border-width-x !important;
  border-bottom-width: $CookieBot-btn-border-width-y !important;
  border-left-width: $CookieBot-btn-border-width-x !important;
  font-size: $CookieBot-btn-font-size !important;
  padding: $CookieBot-btn-padding-y $CookieBot-btn-padding-x !important;
  text-transform: $CookieBot-btn-text-transform !important;
  font-weight: $CookieBot-btn-font-weight !important;
  @extend %linkDecorationHover;

  &:focus,
  &:hover:focus,
  &.focus,
  &:active,
  &.active,
  &:active:focus,
  &:active:hover {
    outline: none !important;
    box-shadow: none !important;
    color: $CookieBot-btn-color-hover !important;
    background: $CookieBot-btn-background-hover !important;
  }
  &:hover {
    border-color: $CookieBot-btn-border-color-hover !important;
    color: $CookieBot-btn-color-hover !important;
  }
}

#CybotCookiebotDialogBodyLevelButtonsSelectPane,
#CybotCookiebotDialogBodyLevelDetailsWrapper {
  border: 0 !important;
  background-color: none !important;
}

#CybotCookiebotDialogBodyLevelButtonAcceptWrapper {
  padding-bottom: $CookieBot-spacer / 2 !important;
}

input[type="checkbox"].CybotCookiebotDialogBodyLevelButton {
  & + label {
    min-height: $CookieBot-checkbox-size !important;
    padding: 0 !important;
    background-image: none !important;
    color: $CookieBot-label-color !important;
    font-weight: $CookieBot-label-font-weight !important;
    font-size: $CookieBot-font-size-base;
    margin: ($CookieBot-spacer / 2) ($CookieBot-spacer / 4);

    &:before {
      content: "";
      display: inline-block;
      z-index: 1;
      position: relative;
      bottom: -2px;
      width: $CookieBot-checkbox-size;
      height: $CookieBot-checkbox-size;
      overflow: hidden;
      border: $CookieBot-checkbox-border-width solid
        $CookieBot-checkbox-border-color;
      margin-right: 4px;
      @if $enable-rounded {
        border-radius: $border-radius;
      }
    }
  }

  &:checked + label {
    &:before {
      background-color: $CookieBot-checkbox-checked-bg;
    }
    &:after {
      content: "";
      display: block;
      z-index: 2;
      position: absolute;
      bottom: 3px; // $CookieBot-checkbox-border-width;
      left: $CookieBot-checkbox-border-width;
      width: (
        $CookieBot-checkbox-size - ($CookieBot-checkbox-border-width * 2)
      );
      height: (
        $CookieBot-checkbox-size - ($CookieBot-checkbox-border-width * 2)
      );
      background-image: $CookieBot-checkbox-checked-bg-image;
      background-color: $CookieBot-checkbox-checked-bg;
    }
  }
}
