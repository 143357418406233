@import "../vendor/hamburgers";
@import "MenuMobile";
@import "MenuMobileLang";
@import "MenuDesktop";

// styles shared across menus
%Menu-product-name {
  display: inline-block;
  padding: 10px 0;
  font-weight: bold;
  @extend %linkDecorationHover;

  &:hover {
    text-decoration: none;
  }
}

#Menu {
  // &_scroller {
  //   display: none;

  //   .MenuDesktop--open & {
  //     display: block;
  //   }
  // }

  &_underneath {
    .MenuDesktop--open & {
      position: fixed;
      left: 0;
      right: 0;
    }
  }
}
