/**
 * @fileOverview
 * 
 * In this folder we just import scss files that are shared accross different
 * stylesheets and that do not emit any css. These files are just used as tools.
 * They divide in [functions](https://sass-lang.com/documentation/at-rules/function),
 * [mixins](https://sass-lang.com/documentation/at-rules/mixin),
 * [placeholders](https://sass-lang.com/documentation/style-rules/placeholder-selectors)
 * and [variables](https://sass-lang.com/documentation/variables).
 * 
 * The content of these files is always made available to `.scss` files imported
 * in JavaScript files bundled with WebPack through the use of
 * [sass-resources-loader](https://github.com/shakacode/sass-resources-loader).
 * 
 * ``js
 *  loader: require.resolve("sass-resources-loader"),
 *  options: {
 *    resources: [
 *      path.join(paths.src.styles, "/config/_.scss"),
 *    ]
 *  }
 *```
 */
@import "../config/functions";
@import "../config/variables";
@import "../config/mixins";
@import "../config/placeholders";

$VideoYouTube__btn-size: 48px;
$VideoYouTube__btn-color: #fff;
$VideoYouTube__msg-color: #fff;

.VideoYouTube {
  position: relative;

  &__thumb {
    .is-ready & {
      opacity: 0;
    }
  }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;

    .is-ready & {
      display: block;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .Spinner {
      position: static;
    }
  }

  &__overlay {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &:before {
      z-index: 0;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background: rgba(0, 0, 0, 0.6);
      opacity: 0.66;
      @include gradientBg(home);
    }

    .can-play & {
      display: none;
    }

    &Msg {
      z-index: 1;
      position: relative;
      display: block;
      color: $VideoYouTube__msg-color;
      text-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
      padding: 10px ($grid-gutter-width / 2) 0;
    }

    &Btn {
      z-index: 1;
      position: relative;
      cursor: pointer;

      svg {
        fill: $VideoYouTube__btn-color;
      }
    }
  }
}
