$MenuMobile-zindex: $zindex-dropdown + 1;
$MenuMobile__link-padding-y: 12px;
$MenuMobile__link-padding-x: $grid-gutter-width / 2;
$MenuMobile__distance-top: 50px;

.MenuMobile {
  display: flex;
  @include desktop() {
    display: none !important;
  }

  .nav-divider {
    height: $MenuMobile__distance-top;
  }

  .nav-item {
    &--languages {
      font-size: $navbar-font-size-small;
    }
  }

  .nav-link {
    display: flex;
    // justify-content: flex-end;
    justify-content: space-between;
    align-items: center;
    padding: $MenuMobile__link-padding-y $MenuMobile__link-padding-x;

    &:hover {
      background: darken($MenuMobile-bg, 2%);
    }
  }

  &__iconPrev {
    @include arrow(left, map-get($Colors, black), 12px, 10px);
    margin-right: 10px;
  }

  &__iconNext {
    @include arrow(right, map-get($Colors, black), 12px, 10px);
  }

  // lock scroll on HTML
  &--open {
    @include media-breakpoint-down(md) {
      overflow: hidden;
    }
  }

  &__toggler {
    margin: 0 $navbar-nav-link-padding-x-mobile;
    border: 0;
    min-height: $navbar-height;

    &:focus {
      outline: none;
    }
  }

  &__content {
    color: map-get($Colors, black);
    position: fixed;
    top: $navbar-height-mobile;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $MenuMobile-zindex;
    background: $MenuMobile-bg;
    overflow-x: hidden;
    overflow-y: scroll;
    transition: transform $App-transition;
    transform: scaleY(0);
    transform-origin: top;
    will-change: transform;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

    > .nav-item:first-child > .nav-link {
      padding-top: $MenuMobile__distance-top;
    }

    // hide mobile menu items underneath current nested submenu
    .nav-item.hasChild.active ~ .nav-item {
      display: none;
    }
  }

  &--open .MenuMobile__content {
    transform: scaleY(1);
  }

  ul {
    @extend %listUnstyled;
  }

  &__submenu {
    // text-align: center;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: $MenuMobile-zindex + 1;
    background: $MenuMobile-bg;
    // box-shadow: -5px 0 20px rgba(0, 0, 0, 0.05);
    // right: -110%;
    left: 0;
    will-change: transform;
    transform: translateX(110%);
    // transition: all 0.4s ease;
    transition: transform 0.4s ease;

    .MenuMobile__submenu {
      z-index: $MenuMobile-zindex + 2;
      .MenuMobile__submenu {
        z-index: $MenuMobile-zindex + 3;
      }
    }

    li.active > & {
      // right: 0;
      transform: translateX(0);
    }

    .nav-link {
      // justify-content: center;
      font-weight: bold;
    }
  }

  &__back {
    > .nav-link {
      padding-top: $MenuMobile__distance-top;
      padding-bottom: ($MenuMobile__distance-top / 2);
      justify-content: flex-start;
      font-weight: normal;
    }
  }
}
