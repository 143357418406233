$MenuMobile-zindex: $zindex-dropdown + 1;
$MenuMobile__link-padding-y: 12px;
$MenuMobile__link-padding-x: $grid-gutter-width / 2;
$MenuMobile__distance-top: 50px;

.MenuMobileLang {
  flex-grow: 2;
  text-align: right;
  @include desktop() {
    display: none !important;
  }
  .nav-item {
    min-height: $navbar-height;
    display: flex;
    justify-content: flex-end;
    span {
      display: flex;
      align-items: center;
      padding: 0 $MenuMobile__link-padding-y;
    }
  }
  &__toggler {
    text-transform: uppercase;
    background-color: transparent;
    margin: 0 $MenuMobile__link-padding-y/2;
    padding: 0 $MenuMobile__link-padding-y;
    border: 0;

    &:focus {
      outline: none;
    }
  }
  &__content {
    color: map-get($Colors, black);
    position: fixed;
    top: $navbar-height-mobile;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $MenuMobile-zindex;
    background: $MenuMobile-bg;
    overflow-x: hidden;
    overflow-y: scroll;
    transition: transform $App-transition;
    transform: scaleY(0);
    transform-origin: top;
    will-change: transform;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

    > .nav-item:first-child {
      min-height: 108px;
      > .nav-link {
        padding-top: $MenuMobile__distance-top;
      }
    }

    // hide mobile menu items underneath current nested submenu
    .nav-item.hasChild.active ~ .nav-item {
      display: none;
    }
  }

  &--open .MenuMobileLang__content {
    transform: scaleY(1);
  }
}
