/**
 * Button with loading state
 */
.btnLoad {
  [data-show-on-loading] {
    display: none;
  }
  [data-hide-on-loading] {
    display: block;
  }

  &.loading {
    [data-show-on-loading] {
      display: block;
    }
    [data-hide-on-loading] {
      display: none;
    }
  }

  .Spinner {
    position: relative;
    top: -2px;
  }
}
