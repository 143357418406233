// set the root font-size, it affect the `rsf` (responsive-font-size) of Bootstrap
html {
  font-size: map-find($Typography, "p", "regular", "fontSize");;
}

// put proportional letter-spacing, it needs to be on each HTML tag that contains
// text to correctly apply
body,
div,
span,
button,
p,
input,
textarea,
label,
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  letter-spacing: 0.1em;
}

// remove it globally, so that we can have big card containers as links
a:focus {
  outline: none;
}
