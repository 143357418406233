$hamburger-class-name: 'hamburger';   
$hamburger-size: 28px;
$hamburger-thickness: 2px; 
$hamburger-color: map-get($Colors, charcoal-grey-two);
$hamburger-color-active: map-get($Colors, charcoal-grey);
$hamburger-background: transparent;
$hamburger-background-active: $hamburger-background;
$hamburger-border-color: transparent;
$hamburger-border-width: 0;
$hamburger-opacity: 1;
$hamburger-opacity-hover: 1;
$hamburger-padding: 0px;
$hamburger-bar-spacing: 8px;
$hamburger-animation-speed: 1;
$hamburger-border-radius: 0;
$hamburger-animations: (
  // apple,
  // arrow,
  // arrow-r,
  // collapse,
  // converge,
  // criss-cross,
  // default,
  // dive,
  // minimal,
  spin,
  // stack,
  // twist
);

$hamburger-bar-size: $hamburger-size - ($hamburger-padding * 2) - ($hamburger-border-width * 2);

@import "~delicious-hamburgers/scss/hamburgers";

.hamburger {
  // as we use `event.target` in `MenuDesktop.js` use pointer-events to avoid
  // the inner and the bars HTML elements of the hamburger to capture the click
  // that in so doing remains on the hamburger container, allowing us to normally
  // use the class `active` on it
  > .inner {
    pointer-events: none;
  }
}
