/**
 * Media: image by default
 */
.media {
  display: flex;
  position: relative;
  height: 100%;
  overflow: hidden;
  background: $App-lazy-bg;

  .Spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    // negative margin-left and margin-top are set inline in the macro
  }

  &Proportion {
    position: relative;
    display: block;
  }

  &Centerer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &Element {
    // z-index: 1;
    position: relative;
    width: 100%;
    height: auto;

    &.lazy {
      // no-js support
      display: none;
    }

    &.lazy-success {
      // fix occasional glitch where opacity stops before reaching 1
      opacity: 1 !important;
    }
  }
}

// state success/error, hide spinner
.slick-lazyload-success + .Spinner,
.lazy-success + .Spinner,
.slick-lazyload-error .Spinner,
.slick-lazyload-error + .Spinner,
.lazy-error .Spinner {
  display: none;
}

/**
 * Media: background image
 */
.media--bg {
  flex: 1;
  height: 100%;

  .mediaElement {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    // background-image is set inline in the template by `jquery.lazy`
    &.slick-lazyload-error,
    &.lazy-error {
      background-size: $img-bg--broken-size;
    }
  }
}

/**
 * Media: Iframe
 */
.media--iframe {
  .mediaElement {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: visible;
  }
}

/**
 * Media: video
 */
.media--video {
  .mediaElement {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: visible;
  }
}
