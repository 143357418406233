%font-family-body {
  font-family: $font-family-body;
}

%font-family-headings {
  font-family: $font-family-headings;
  font-style: normal;
  font-stretch: condensed;
}

%listUnstyled {
  list-style: none;
  margin: 0;
  padding: 0;
}

%linkDecoration {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    // width: 0;
    height: $linkDecoration-thickness;
    display: block;
    margin-top: 5px;
    left: 50%;
    right: 50%;
    background: $linkDecoration-color;
    transition: right $App-transition, left $App-transition;
  }
}

%linkDecorationActive {
  &:after {
    // width: 100%;
    background: $linkDecoration-color-hover;
    left: 0;
    right: 0;
  }
}

%linkDecorationHover {
  @extend %linkDecoration;

  &:hover {
    text-decoration: none;
    @extend %linkDecorationActive;
  }
}

%gradient-bg {
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
}

%ProductTitle {
  color: #000;
  font-family: $font-family-body;
  font-stretch: normal;
  text-transform: uppercase;
}
